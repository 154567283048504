import {defineStore} from 'pinia';
import storage from '~/utils/storage';
import {toRaw} from 'vue';
import isEmpty from 'lodash/isEmpty'
import routeUtil from "~/utils/route";
import {EnvStore} from "~/common-store/env-store";
import axios from "~/helpers/axios";
import {SUCCESS_CODE, USER_TYPE_MEMBER_AGENT} from "~/consts/const";
import notify from "~/utils/notify";

const KEY_JWT = 'mbr_jwt';
const KEY_USERINFO = 'mbr_userinfo';

export const UserStore = defineStore('user-store-LB8he', {
    state: () => ({
        _jwt: '',
        _jwtLoaded: false,
        _userInfo: {},
        _userInfoLoaded: false,
    }),
    getters: {
        isMemberAgent() {
            return this._userInfo.MbrIsAgent === USER_TYPE_MEMBER_AGENT
        }
    },
    actions: {
        async jwt() {
            if (this._jwtLoaded) {
                return this._jwt;
            }
            this._jwt = await storage.getItem(KEY_JWT);
            this._jwtLoaded = true;
            return this._jwt;
        },
        async userInfo() {
            if (this._userInfoLoaded) {
                return this._userInfo;
            }
            this._userInfo = await storage.getItem(KEY_USERINFO);
            this._userInfoLoaded = true;
            return this._userInfo;
        },
        async loadDataFromLocal() {
            if (!this.jwtLoaded) {
                this._jwt = await storage.getItem(KEY_JWT);
                this.jwtLoaded = true;
            }

            if (!this._userInfoLoaded) {
                this._userInfo = await storage.getItem(KEY_USERINFO);
                this._userInfoLoaded = true;
            }
        },
        async imAutoLogin() {
            const conf = EnvStore().config;
            await axios({
                method: 'get',
                url: `${conf.ServerHost}/api/v1/mbr/im/login/auto`,
                params: {
                    jwt: routeUtil.getQuery().jwt,
                }
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp)
                    return
                }

                const {Jwt, UserInfo} = resp.data.Data;

                this.jwtUpdate(Jwt)
                this.userInfoUpdate(UserInfo)

            }).catch(async (err) => {
                console.log('/api/v1/mbr/login/jwt', err)
            })
        },
        hasLogin() {
            if (!this._jwt) {
                return false
            }
            if (isEmpty(this._userInfo)) {
                return false
            }

            return true
        },
        async logout() {
            await storage.removeItem(KEY_JWT);
            await storage.removeItem(KEY_USERINFO);
            this.$reset();
            window.location.reload();
        },
        async jwtUpdate(jwt) {
            this._jwt = jwt;
            await storage.setItem(KEY_JWT, jwt);
        },
        async userInfoUpdate(userInfo) {
            const fmtObj = toRaw(userInfo);
            this._userInfo = fmtObj;
            await storage.setItem(KEY_USERINFO, fmtObj);
        },
    },
});
