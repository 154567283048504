import { default as child_45commission_45ratio_45update_45modal1xMHuOyhCVMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/child-members/child-commission-ratio-update-modal.vue?macro=true";
import { default as child_45members_45storezurhGRt6PDMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/child-members/child-members-store.js?macro=true";
import { default as child_45remark_45update_45modaljea0SpfGjWMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/child-members/child-remark-update-modal.vue?macro=true";
import { default as pageopVBKgHDWXMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/child-members/page.vue?macro=true";
import { default as cny_45do_45list_45storeWkV1EGxq9bMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/do/cny-do-list/cny-do-list-store.js?macro=true";
import { default as pagef2kNKhgP3XMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/do/cny-do-list/page.vue?macro=true";
import { default as time_45out_45payment_45voucher_45upload_45modaleUbotiAGo2Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/do/cny-do-list/time-out-payment-voucher-upload-modal.vue?macro=true";
import { default as time_45out_45payment_45voucher_45upload_45storeEyZ5jz5CboMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/do/cny-do-list/time-out-payment-voucher-upload-store.js?macro=true";
import { default as home_45store1PHLskbsT9Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/home/home-store.js?macro=true";
import { default as homeStatisticDirectMemberTable5nQ66KO8x1Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/home/homeStatisticDirectMemberTable.vue?macro=true";
import { default as homeStatisticQualTable9xlZKJ9PT2Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/home/homeStatisticQualTable.vue?macro=true";
import { default as pageNutIFVfnriMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/home/page.vue?macro=true";
import { default as mobile_45app_45pagepscW6nWK35Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/im/mobile-app-page.vue?macro=true";
import { default as mobilegJvPLUIFDzMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/im/mobile.vue?macro=true";
import { default as pageaKYo2uNRmJMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/im/page.vue?macro=true";
import { default as pckiPDl0WWouMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/im/pc.vue?macro=true";
import { default as indexkfoRmQG9zqMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/index.vue?macro=true";
import { default as login_45store4ultbal1hJMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/login/login-store.js?macro=true";
import { default as pagenvdqmuD2q1Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/login/page.vue?macro=true";
import { default as detailtL4GLedqYPMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/qual/alipay/detail.vue?macro=true";
import { default as pagevV1BqpQcxeMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/qual/alipay/page.vue?macro=true";
import { default as qual_alipay_detail_storeXd7IEQeN5wMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/qual/alipay/qual_alipay_detail_store.js?macro=true";
import { default as qual_alipay_storeoiKQ72x0oVMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/qual/alipay/qual_alipay_store.js?macro=true";
import { default as pageRgTZWs7oH6Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/register/page.vue?macro=true";
import { default as register_45storepmfTithOPZMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/register/register-store.js?macro=true";
import { default as pageBVUlb0vDRcMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/registerBak/page.vue?macro=true";
import { default as phone_45verify_45pagePGQLPLoUIfMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/registerBak/phone-verify-page.vue?macro=true";
import { default as register_45storedpoo7iuRiBMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/registerBak/register-store.js?macro=true";
import { default as pageg127UBbAKEMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/reset-password-bak/page.vue?macro=true";
import { default as phone_45verify_45pageeY9GihAdhvMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/reset-password-bak/phone-verify-page.vue?macro=true";
import { default as reset_45pageXKIi1xwd1YMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/reset-password-bak/reset-page.vue?macro=true";
import { default as reset_45password_45store8DYeNEmeFIMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/reset-password-bak/reset-password-store.js?macro=true";
import { default as page2Vq82tHKBuMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/reset-password/page.vue?macro=true";
import { default as reset_45password_45store3oh5yTFSYZMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/reset-password/reset-password-store.js?macro=true";
import { default as detailX02uUaSdLvMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/station-letter/detail.vue?macro=true";
import { default as pageNxQA7HM71jMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/station-letter/page.vue?macro=true";
import { default as station_45letter_45detail_45storeRNQzFdEo6CMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/station-letter/station-letter-detail-store.js?macro=true";
import { default as station_45letter_45storeRDhmwBkuVrMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/station-letter/station-letter-store.js?macro=true";
import { default as page3QYt2uNiC0Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/user-center/page.vue?macro=true";
import { default as user_45center_45storehh23ZHR0h8Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/user-center/user-center-store.js?macro=true";
import { default as cny_45bill_45storelwovJtknBTMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny-bill/cny-bill-store.js?macro=true";
import { default as pageWZFk6m87dyMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny-bill/page.vue?macro=true";
import { default as mbr_45self_45balance_45cny_45to_45usdt_45storenCIsX88bsUMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-balance-cny-to-usdt/mbr-self-balance-cny-to-usdt-store.js?macro=true";
import { default as MbrSelfBalanceCnyToUsdtListnxInbEMMgiMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-balance-cny-to-usdt/MbrSelfBalanceCnyToUsdtList.vue?macro=true";
import { default as MbrSelfBalanceCnyToUsdtModal1HXEoGdewiMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-balance-cny-to-usdt/MbrSelfBalanceCnyToUsdtModal.vue?macro=true";
import { default as mbr_45self_45commission_45cny_45to_45balance_45cny_45storeJm8DqlXarfMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-commission-cny-to-balance-cny/mbr-self-commission-cny-to-balance-cny-store.js?macro=true";
import { default as MbrSelfCommissionCnyToBalanceCnyList1vTFKHNJMDMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-commission-cny-to-balance-cny/MbrSelfCommissionCnyToBalanceCnyList.vue?macro=true";
import { default as MbrSelfCommissionCnyToBalanceCnyModaltw1Eq4AM5eMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-commission-cny-to-balance-cny/MbrSelfCommissionCnyToBalanceCnyModal.vue?macro=true";
import { default as mbr_45self_45commission_45cny_45to_45usdt_45storeGtIWPi6i86Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-commission-cny-to-usdt/mbr-self-commission-cny-to-usdt-store.js?macro=true";
import { default as MbrSelfCommissionCnyToUsdtListbbBK81t2mGMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-commission-cny-to-usdt/MbrSelfCommissionCnyToUsdtList.vue?macro=true";
import { default as MbrSelfCommissionCnyToUsdtModalRKWoC8ZO4gMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-commission-cny-to-usdt/MbrSelfCommissionCnyToUsdtModal.vue?macro=true";
import { default as pageWEYQ4QN0IqMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/page.vue?macro=true";
import { default as wallet_45cny_45storeLq32vWlDs2Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/wallet-cny-store.js?macro=true";
import { default as pageOgltjbbDpJMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/page.vue?macro=true";
import { default as pageQ35DdRBU0cMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt-bill/page.vue?macro=true";
import { default as usdt_45bill_45storen5fJfUVxdxMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt-bill/usdt-bill-store.js?macro=true";
import { default as mbr_45self_45balance_45usdt_45to_45cny_45store7ghs9gbWSGMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/mbr-self-balance-usdt-to-cny/mbr-self-balance-usdt-to-cny-store.js?macro=true";
import { default as MbrSelfBalanceUsdtToCnyList8s3Ueiv67HMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/mbr-self-balance-usdt-to-cny/MbrSelfBalanceUsdtToCnyList.vue?macro=true";
import { default as MbrSelfBalanceUsdtToCnyModalSMTZRr4oxOMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/mbr-self-balance-usdt-to-cny/MbrSelfBalanceUsdtToCnyModal.vue?macro=true";
import { default as mbr_45trans_45to_45mbr_45storef7bkXp3gWkMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/mbr-trans-to-mbr/mbr-trans-to-mbr-store.js?macro=true";
import { default as MbrTransToMbrListxxUihsJuscMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/mbr-trans-to-mbr/MbrTransToMbrList.vue?macro=true";
import { default as MbrTransToMbrModalzs6bshHgonMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/mbr-trans-to-mbr/MbrTransToMbrModal.vue?macro=true";
import { default as page990RkezD6pMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/page.vue?macro=true";
import { default as RechargeListCsZSwYb2WLMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/recharge/RechargeList.vue?macro=true";
import { default as RechargeModalfNfp6dzAngMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/recharge/RechargeModal.vue?macro=true";
import { default as usdt_45recharge_45storeIj4pRihj6xMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/recharge/usdt-recharge-store.js?macro=true";
import { default as wallet_45usdt_45storeTAmka3gDsYMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/wallet-usdt-store.js?macro=true";
import { default as usdt_45withdrawals_45commission_45to_45balance_45storejlEchFJyIGMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/withdrawals-commission-to-balance/usdt-withdrawals-commission-to-balance-store.js?macro=true";
import { default as WithdrawalsCommissionToBalanceListekc3Rj1a3VMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/withdrawals-commission-to-balance/WithdrawalsCommissionToBalanceList.vue?macro=true";
import { default as WithdrawalsCommissionToBalanceModalqL1aJwTPrJMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/withdrawals-commission-to-balance/WithdrawalsCommissionToBalanceModal.vue?macro=true";
import { default as usdt_45withdrawals_45storeA47nSuU5O1Meta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/withdrawals/usdt-withdrawals-store.js?macro=true";
import { default as WithdrawalsListtBMBDEfcrLMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/withdrawals/WithdrawalsList.vue?macro=true";
import { default as WithdrawalsModalHnnCjjKNjWMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/withdrawals/WithdrawalsModal.vue?macro=true";
import { default as wallet_45storejv6XpHWg1IMeta } from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/wallet-store.js?macro=true";
export default [
  {
    name: "child-members-child-commission-ratio-update-modal",
    path: "/child-members/child-commission-ratio-update-modal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/child-members/child-commission-ratio-update-modal.vue").then(m => m.default || m)
  },
  {
    name: "child-members-child-members-store",
    path: "/child-members/child-members-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/child-members/child-members-store.js").then(m => m.default || m)
  },
  {
    name: "child-members-child-remark-update-modal",
    path: "/child-members/child-remark-update-modal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/child-members/child-remark-update-modal.vue").then(m => m.default || m)
  },
  {
    name: "child-members-page",
    path: "/child-members/page",
    meta: pageopVBKgHDWXMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/child-members/page.vue").then(m => m.default || m)
  },
  {
    name: "do-cny-do-list-cny-do-list-store",
    path: "/do/cny-do-list/cny-do-list-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/do/cny-do-list/cny-do-list-store.js").then(m => m.default || m)
  },
  {
    name: "do-cny-do-list-page",
    path: "/do/cny-do-list/page",
    meta: pagef2kNKhgP3XMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/do/cny-do-list/page.vue").then(m => m.default || m)
  },
  {
    name: "do-cny-do-list-time-out-payment-voucher-upload-modal",
    path: "/do/cny-do-list/time-out-payment-voucher-upload-modal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/do/cny-do-list/time-out-payment-voucher-upload-modal.vue").then(m => m.default || m)
  },
  {
    name: "do-cny-do-list-time-out-payment-voucher-upload-store",
    path: "/do/cny-do-list/time-out-payment-voucher-upload-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/do/cny-do-list/time-out-payment-voucher-upload-store.js").then(m => m.default || m)
  },
  {
    name: "home-home-store",
    path: "/home/home-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/home/home-store.js").then(m => m.default || m)
  },
  {
    name: "home-homeStatisticDirectMemberTable",
    path: "/home/homeStatisticDirectMemberTable",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/home/homeStatisticDirectMemberTable.vue").then(m => m.default || m)
  },
  {
    name: "home-homeStatisticQualTable",
    path: "/home/homeStatisticQualTable",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/home/homeStatisticQualTable.vue").then(m => m.default || m)
  },
  {
    name: "home-page",
    path: "/home/page",
    meta: pageNutIFVfnriMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/home/page.vue").then(m => m.default || m)
  },
  {
    name: "im-mobile-app-page",
    path: "/im/mobile-app-page",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/im/mobile-app-page.vue").then(m => m.default || m)
  },
  {
    name: "im-mobile",
    path: "/im/mobile",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/im/mobile.vue").then(m => m.default || m)
  },
  {
    name: "im-page",
    path: "/im/page",
    meta: pageaKYo2uNRmJMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/im/page.vue").then(m => m.default || m)
  },
  {
    name: "im-pc",
    path: "/im/pc",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/im/pc.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexkfoRmQG9zqMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login-login-store",
    path: "/login/login-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/login/login-store.js").then(m => m.default || m)
  },
  {
    name: "login-page",
    path: "/login/page",
    meta: pagenvdqmuD2q1Meta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/login/page.vue").then(m => m.default || m)
  },
  {
    name: "qual-alipay-detail",
    path: "/qual/alipay/detail",
    meta: detailtL4GLedqYPMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/qual/alipay/detail.vue").then(m => m.default || m)
  },
  {
    name: "qual-alipay-page",
    path: "/qual/alipay/page",
    meta: pagevV1BqpQcxeMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/qual/alipay/page.vue").then(m => m.default || m)
  },
  {
    name: "qual-alipay-qual_alipay_detail_store",
    path: "/qual/alipay/qual_alipay_detail_store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/qual/alipay/qual_alipay_detail_store.js").then(m => m.default || m)
  },
  {
    name: "qual-alipay-qual_alipay_store",
    path: "/qual/alipay/qual_alipay_store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/qual/alipay/qual_alipay_store.js").then(m => m.default || m)
  },
  {
    name: "register-page",
    path: "/register/page",
    meta: pageRgTZWs7oH6Meta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/register/page.vue").then(m => m.default || m)
  },
  {
    name: "register-register-store",
    path: "/register/register-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/register/register-store.js").then(m => m.default || m)
  },
  {
    name: "registerBak-page",
    path: "/registerBak/page",
    meta: pageBVUlb0vDRcMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/registerBak/page.vue").then(m => m.default || m)
  },
  {
    name: "registerBak-phone-verify-page",
    path: "/registerBak/phone-verify-page",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/registerBak/phone-verify-page.vue").then(m => m.default || m)
  },
  {
    name: "registerBak-register-store",
    path: "/registerBak/register-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/registerBak/register-store.js").then(m => m.default || m)
  },
  {
    name: "reset-password-bak-page",
    path: "/reset-password-bak/page",
    meta: pageg127UBbAKEMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/reset-password-bak/page.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-bak-phone-verify-page",
    path: "/reset-password-bak/phone-verify-page",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/reset-password-bak/phone-verify-page.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-bak-reset-page",
    path: "/reset-password-bak/reset-page",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/reset-password-bak/reset-page.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-bak-reset-password-store",
    path: "/reset-password-bak/reset-password-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/reset-password-bak/reset-password-store.js").then(m => m.default || m)
  },
  {
    name: "reset-password-page",
    path: "/reset-password/page",
    meta: page2Vq82tHKBuMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/reset-password/page.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-reset-password-store",
    path: "/reset-password/reset-password-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/reset-password/reset-password-store.js").then(m => m.default || m)
  },
  {
    name: "station-letter-detail",
    path: "/station-letter/detail",
    meta: detailX02uUaSdLvMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/station-letter/detail.vue").then(m => m.default || m)
  },
  {
    name: "station-letter-page",
    path: "/station-letter/page",
    meta: pageNxQA7HM71jMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/station-letter/page.vue").then(m => m.default || m)
  },
  {
    name: "station-letter-station-letter-detail-store",
    path: "/station-letter/station-letter-detail-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/station-letter/station-letter-detail-store.js").then(m => m.default || m)
  },
  {
    name: "station-letter-station-letter-store",
    path: "/station-letter/station-letter-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/station-letter/station-letter-store.js").then(m => m.default || m)
  },
  {
    name: "user-center-page",
    path: "/user-center/page",
    meta: page3QYt2uNiC0Meta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/user-center/page.vue").then(m => m.default || m)
  },
  {
    name: "user-center-user-center-store",
    path: "/user-center/user-center-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/user-center/user-center-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-cny-bill-cny-bill-store",
    path: "/wallet/cny-bill/cny-bill-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny-bill/cny-bill-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-cny-bill-page",
    path: "/wallet/cny-bill/page",
    meta: pageWZFk6m87dyMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny-bill/page.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cny-mbr-self-balance-cny-to-usdt-mbr-self-balance-cny-to-usdt-store",
    path: "/wallet/cny/mbr-self-balance-cny-to-usdt/mbr-self-balance-cny-to-usdt-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-balance-cny-to-usdt/mbr-self-balance-cny-to-usdt-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-cny-mbr-self-balance-cny-to-usdt-MbrSelfBalanceCnyToUsdtList",
    path: "/wallet/cny/mbr-self-balance-cny-to-usdt/MbrSelfBalanceCnyToUsdtList",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-balance-cny-to-usdt/MbrSelfBalanceCnyToUsdtList.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cny-mbr-self-balance-cny-to-usdt-MbrSelfBalanceCnyToUsdtModal",
    path: "/wallet/cny/mbr-self-balance-cny-to-usdt/MbrSelfBalanceCnyToUsdtModal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-balance-cny-to-usdt/MbrSelfBalanceCnyToUsdtModal.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cny-mbr-self-commission-cny-to-balance-cny-mbr-self-commission-cny-to-balance-cny-store",
    path: "/wallet/cny/mbr-self-commission-cny-to-balance-cny/mbr-self-commission-cny-to-balance-cny-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-commission-cny-to-balance-cny/mbr-self-commission-cny-to-balance-cny-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-cny-mbr-self-commission-cny-to-balance-cny-MbrSelfCommissionCnyToBalanceCnyList",
    path: "/wallet/cny/mbr-self-commission-cny-to-balance-cny/MbrSelfCommissionCnyToBalanceCnyList",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-commission-cny-to-balance-cny/MbrSelfCommissionCnyToBalanceCnyList.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cny-mbr-self-commission-cny-to-balance-cny-MbrSelfCommissionCnyToBalanceCnyModal",
    path: "/wallet/cny/mbr-self-commission-cny-to-balance-cny/MbrSelfCommissionCnyToBalanceCnyModal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-commission-cny-to-balance-cny/MbrSelfCommissionCnyToBalanceCnyModal.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cny-mbr-self-commission-cny-to-usdt-mbr-self-commission-cny-to-usdt-store",
    path: "/wallet/cny/mbr-self-commission-cny-to-usdt/mbr-self-commission-cny-to-usdt-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-commission-cny-to-usdt/mbr-self-commission-cny-to-usdt-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-cny-mbr-self-commission-cny-to-usdt-MbrSelfCommissionCnyToUsdtList",
    path: "/wallet/cny/mbr-self-commission-cny-to-usdt/MbrSelfCommissionCnyToUsdtList",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-commission-cny-to-usdt/MbrSelfCommissionCnyToUsdtList.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cny-mbr-self-commission-cny-to-usdt-MbrSelfCommissionCnyToUsdtModal",
    path: "/wallet/cny/mbr-self-commission-cny-to-usdt/MbrSelfCommissionCnyToUsdtModal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/mbr-self-commission-cny-to-usdt/MbrSelfCommissionCnyToUsdtModal.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cny-page",
    path: "/wallet/cny/page",
    meta: pageWEYQ4QN0IqMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/page.vue").then(m => m.default || m)
  },
  {
    name: "wallet-cny-wallet-cny-store",
    path: "/wallet/cny/wallet-cny-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/cny/wallet-cny-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-page",
    path: "/wallet/page",
    meta: pageOgltjbbDpJMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/page.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-bill-page",
    path: "/wallet/usdt-bill/page",
    meta: pageQ35DdRBU0cMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt-bill/page.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-bill-usdt-bill-store",
    path: "/wallet/usdt-bill/usdt-bill-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt-bill/usdt-bill-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-mbr-self-balance-usdt-to-cny-mbr-self-balance-usdt-to-cny-store",
    path: "/wallet/usdt/mbr-self-balance-usdt-to-cny/mbr-self-balance-usdt-to-cny-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/mbr-self-balance-usdt-to-cny/mbr-self-balance-usdt-to-cny-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-mbr-self-balance-usdt-to-cny-MbrSelfBalanceUsdtToCnyList",
    path: "/wallet/usdt/mbr-self-balance-usdt-to-cny/MbrSelfBalanceUsdtToCnyList",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/mbr-self-balance-usdt-to-cny/MbrSelfBalanceUsdtToCnyList.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-mbr-self-balance-usdt-to-cny-MbrSelfBalanceUsdtToCnyModal",
    path: "/wallet/usdt/mbr-self-balance-usdt-to-cny/MbrSelfBalanceUsdtToCnyModal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/mbr-self-balance-usdt-to-cny/MbrSelfBalanceUsdtToCnyModal.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-mbr-trans-to-mbr-mbr-trans-to-mbr-store",
    path: "/wallet/usdt/mbr-trans-to-mbr/mbr-trans-to-mbr-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/mbr-trans-to-mbr/mbr-trans-to-mbr-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-mbr-trans-to-mbr-MbrTransToMbrList",
    path: "/wallet/usdt/mbr-trans-to-mbr/MbrTransToMbrList",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/mbr-trans-to-mbr/MbrTransToMbrList.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-mbr-trans-to-mbr-MbrTransToMbrModal",
    path: "/wallet/usdt/mbr-trans-to-mbr/MbrTransToMbrModal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/mbr-trans-to-mbr/MbrTransToMbrModal.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-page",
    path: "/wallet/usdt/page",
    meta: page990RkezD6pMeta || {},
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/page.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-recharge-RechargeList",
    path: "/wallet/usdt/recharge/RechargeList",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/recharge/RechargeList.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-recharge-RechargeModal",
    path: "/wallet/usdt/recharge/RechargeModal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/recharge/RechargeModal.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-recharge-usdt-recharge-store",
    path: "/wallet/usdt/recharge/usdt-recharge-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/recharge/usdt-recharge-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-wallet-usdt-store",
    path: "/wallet/usdt/wallet-usdt-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/wallet-usdt-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-withdrawals-commission-to-balance-usdt-withdrawals-commission-to-balance-store",
    path: "/wallet/usdt/withdrawals-commission-to-balance/usdt-withdrawals-commission-to-balance-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/withdrawals-commission-to-balance/usdt-withdrawals-commission-to-balance-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-withdrawals-commission-to-balance-WithdrawalsCommissionToBalanceList",
    path: "/wallet/usdt/withdrawals-commission-to-balance/WithdrawalsCommissionToBalanceList",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/withdrawals-commission-to-balance/WithdrawalsCommissionToBalanceList.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-withdrawals-commission-to-balance-WithdrawalsCommissionToBalanceModal",
    path: "/wallet/usdt/withdrawals-commission-to-balance/WithdrawalsCommissionToBalanceModal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/withdrawals-commission-to-balance/WithdrawalsCommissionToBalanceModal.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-withdrawals-usdt-withdrawals-store",
    path: "/wallet/usdt/withdrawals/usdt-withdrawals-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/withdrawals/usdt-withdrawals-store.js").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-withdrawals-WithdrawalsList",
    path: "/wallet/usdt/withdrawals/WithdrawalsList",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/withdrawals/WithdrawalsList.vue").then(m => m.default || m)
  },
  {
    name: "wallet-usdt-withdrawals-WithdrawalsModal",
    path: "/wallet/usdt/withdrawals/WithdrawalsModal",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/usdt/withdrawals/WithdrawalsModal.vue").then(m => m.default || m)
  },
  {
    name: "wallet-wallet-store",
    path: "/wallet/wallet-store",
    component: () => import("/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/pages/wallet/wallet-store.js").then(m => m.default || m)
  }
]