import axios from 'axios';
import {UserStore} from "~/store/user-store";
import {EnvStore} from "~/common-store/env-store";

axios.interceptors.request.use(
    async (config) => {
        if (config.timeout === 0) {
            config.timeout = 20000; // 默认20秒请求超时
        }
        const userStore = UserStore();
        const jwt = await userStore.jwt();
        if (jwt) {
            config.headers.Authorization = jwt;
        }

        const conf = EnvStore().config
        config.headers.Platform = conf.Platform;

        return config;
    },
    (error) => Promise.reject(error),
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (!error.response) {
            return Promise.reject(error);
        }
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            UserStore().logout();

            // 禁用重试
            originalRequest._retry = false;

            return Promise.resolve(error.response.data);
        }

        return Promise.reject(error);
    },
);


export default axios;
