import {defineStore} from 'pinia';
import storage from '~/utils/storage';
import {toRaw} from 'vue';
import isEmpty from 'lodash/isEmpty'
import routeUtil from "~/utils/route";
import {GlobalStore} from "~/common-store/global-store";
import {EnvStore} from "~/common-store/env-store";
import axios from "~/helpers/axios";
import {SUCCESS_CODE} from "~/consts/const";
import notify from "~/utils/notify";

export const GlobalPlatformInfoStore = defineStore('global-platform-info-store-5rewrEcOlZ', {
    state: () => ({
        platformInfo: {},
        fetchPlatformInfoIng: false,
    }),
    getters: {
        platformLogoFullText() {
            return this.platformInfo.PlatformInfoLogoTextFull
        }
    },
    actions: {
        //管理后台
        async fetchPlatformInfo() {
            if (this.fetchPlatformInfoIng) {
                return
            }
            this.fetchPlatformInfoIng = true

            const conf = EnvStore().config;

            await axios({
                method: 'get',
                url: `${conf.ServerHost}/api/v1/platform-info/mbr-backend`,
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp)
                    return
                }
                this.platformInfo = resp.data.Data;

            }).catch(async (err) => {
                console.log('/api/v1/platform-info/mbr-backend', err)
            }).finally(() => {
                this.fetchPlatformInfoIng = false
            })
        },
    },
});
