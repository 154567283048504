import revive_payload_client_T3O7TJv2Jr from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.68_encoding@0.1.13_ioredis@5.4.1_magicast_qa2tdxkdmgond44n4vedw54hke/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_e6puP7emrG from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.68_encoding@0.1.13_ioredis@5.4.1_magicast_qa2tdxkdmgond44n4vedw54hke/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1PENq3kKSX from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.68_encoding@0.1.13_ioredis@5.4.1_magicast_qa2tdxkdmgond44n4vedw54hke/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_JxLHIjd9Pn from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.68_encoding@0.1.13_ioredis@5.4.1_magicast_qa2tdxkdmgond44n4vedw54hke/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_312fRJP8Y0 from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.68_encoding@0.1.13_ioredis@5.4.1_magicast_qa2tdxkdmgond44n4vedw54hke/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ZRqkLZybY4 from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.68_encoding@0.1.13_ioredis@5.4.1_magicast_qa2tdxkdmgond44n4vedw54hke/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rtIybcRvmn from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.68_encoding@0.1.13_ioredis@5.4.1_magicast_qa2tdxkdmgond44n4vedw54hke/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_h7eQUytB5g from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.28.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/.nuxt/components.plugin.mjs";
import prefetch_client_T6By7M2num from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.5.0_@types+node@18.19.68_encoding@0.1.13_ioredis@5.4.1_magicast_qa2tdxkdmgond44n4vedw54hke/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/.nuxt/primevue-plugin.mjs";
import plugin_client_S9E9h5qxAn from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/node_modules/.pnpm/nuxt-primevue@0.2.2_magicast@0.3.5_rollup@4.28.1_vue@3.5.13_typescript@5.7.2_/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import lang_G9PR3PZD5Q from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/plugins/lang.js";
import loading_JsMeH12IfP from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/plugins/loading.js";
import v_md_editor_P2TPih41PN from "/root/.jenkins/workspace/GPA-PRO-ALL-customer-fe-build/member-backend/plugins/v-md-editor.js";
export default [
  revive_payload_client_T3O7TJv2Jr,
  unhead_e6puP7emrG,
  router_1PENq3kKSX,
  payload_client_JxLHIjd9Pn,
  navigation_repaint_client_312fRJP8Y0,
  check_outdated_build_client_ZRqkLZybY4,
  chunk_reload_client_rtIybcRvmn,
  plugin_vue3_h7eQUytB5g,
  components_plugin_KR1HBZs4kY,
  prefetch_client_T6By7M2num,
  primevue_plugin_egKpok8Auk,
  plugin_client_S9E9h5qxAn,
  lang_G9PR3PZD5Q,
  loading_JsMeH12IfP,
  v_md_editor_P2TPih41PN
]